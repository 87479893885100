import axios from "../utils/axios1";
import baseUrl from "../utils/baseUrl";

const service = () => {
  const loginUser = async (auth) => {
    return axios.post("/user_token", auth);
  };
  const currentUser = () => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get("/current_user", {
        headers: { Authorization: token },
      });
    }
  };

  const getDoctorCurrentSlots = (queryString) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_available_slots?${queryString}`, {
        headers: { Authorization: token },
      });
    }
  };

  const logoutUser = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/logout/${id}`, {
        headers: { Authorization: token },
      });
    }
  };
  const fetchIndividualPatient = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let urlData = url ? `?${url}` :''
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_today_visits${urlData}`, {
        headers: { Authorization: token },
      });
    }
  };
  const fetchMsoInformation = (userId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/users/${userId}`, {
        headers: { Authorization: token },
      });
    }
  };

  const searchPatient = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_patients?${url}`, {
        headers: { Authorization: token },
      });
    }
  };

  const searchPatientByDiagnosis = (patientId, diagnosis) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_prescriptions_by_diagnosis/${patientId}/${diagnosis}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchMedicalProblem = (patientId, visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_medical_problems_by_visit_id/${patientId}/${visitId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchCurrentMedication = (patientId, visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_current_medications_by_visit_id/${patientId}/${visitId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchVital = (patientId, visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_vitals_by_visit_id/${patientId}/${visitId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchLifestyle = (patientId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_patient_lifestyles_by_patient_id/${patientId}`, {
        headers: { Authorization: token },
      });
    }
  };
  const fetchFamilyHistory = (patientId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_family_history_by_patient_id/${patientId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchFamilyHistoryEditData = (patientId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_family_history_by_patient_id/${patientId}?is_editable=1`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchDocuments = (patientId, visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_documents_by_visit_id/${patientId}/${visitId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  //   const fetchIndividualPatient = () => {
  //   if (
  //     localStorage.getItem("jwt") !== "" ||
  //     localStorage.getItem("jwt") !== undefined
  //   ) {
  //     let token = "Bearer " + localStorage.getItem("jwt");
  //     fetch(`${baseUrl}/get_doctor_today_visits`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: token,
  //       },
  //     }).then(response=>response.json())
  //     // .then(data=>data)
  //   }
  // };

  const fetchCategoryPatient = (id,url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let urlData = url ? `?${url}` :''
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_category_today_visits/${id}${urlData}`, {
        headers: { Authorization: token },
      });
    }
  };

  const selfAssignPatient = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/assign_visit_to_doctor/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const calenderCount = (month) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_doctor_appointments_count_by_month?month=${month}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const calenderAppointment = (date) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_doctor_appointments_by_date?appointment_date=${date}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const timelineData = (patientId, visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(
        `/get_patient_timelines?patient_id=${patientId}&visit_id=${visitId}`,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const editMedicalProblem = (formData, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_patient_medical_problems/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  const editVital = (formData, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_patient_vitals/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  const editFamilyHistory = (formData, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_patient_family_histories/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  const editLifeStyle = (formData, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_patient_lifestyles/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  const fetchPatientDetail = (patientId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/patients/${patientId}`, {
        headers: { Authorization: token },
      });
    }
  };
  const searchFormulary = (drug) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_drug_data?act=${drug.act}&term=${drug.term}&patient-id=${drug.patient_id}`, {
        headers: { Authorization: token },
      });
    }
  };
  
  const searchPendingTempDrug = (tempFormulary) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/search_pending_temp_drugs?drug_name=${tempFormulary}`, {
        headers: { Authorization: token },
      });
    }
  };

  const addFormulary = async (drug_formulary) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/drug_formularies`,
        { drug_formulary: drug_formulary },
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  // const allInteraction = (formData) => {
  //   if (
  //     localStorage.getItem("jwt") !== "" ||
  //     localStorage.getItem("jwt") !== undefined
  //   ) {
  //     let token = "Bearer " + localStorage.getItem("jwt");
  //     return axios.get(`/get_cims_data/?act=all_interactions&${formData}`, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //   }
  // };

  // const allInteraction = (formData)=>{
  //   if (
  //     localStorage.getItem("jwt") !== "" ||
  //     localStorage.getItem("jwt") !== undefined
  //   ) {
  //     let token = "Bearer " + localStorage.getItem("jwt");
  //     return fetch(
  //       `${baseUrl}/get_cims_data/?act=all_interactions&${formData}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: token,
  //         },
  //         // body: formData,
  //       }
  //     ).then(response=>response.text())
  //      .then(data=>console.log(data.data_length))
  //   }
  // }

  const PathologySuggestion = (params) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_pathology_tests/?${params}`, {
        headers: { Authorization: token },
      });
    }
  };
  const RadiologySuggestion = (params) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_radiology_tests/?${params}`, {
        headers: { Authorization: token },
      });
    }
  };
  const DietSuggestion = (params) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_diet_notes?${params}`, {
        headers: { Authorization: token },
      });
    }
  };

  const ExerciseSuggestion = (params) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_exercises/?${params}`, {
        headers: { Authorization: token },
      });
    }
  };
  const DifferentialDiagnosis = (params) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_icd10_differential_diagnosis/?${params}`, {
        headers: { Authorization: token },
      });
    }
  };

  const SaveAllergy = (id, formData) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_patient_allergies/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  const fetchAllergy = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`get_patient_allergies_by_patient_id/${id}`, {
        headers: { Authorization: token },
      });
    }
  };
  const searchDrugFromFormulary = (drug) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`get_drug_formulary_dropdown?drug_name=${drug}`, {
        headers: { Authorization: token },
      });
    }
  };

  const flowBoardStatus = (appointment_id, patient_id, status) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/update_flow_board_status/${appointment_id}/${patient_id}`,
        { status: status },
        { headers: { Authorization: token } }
      );
    }
  };

  const fetchFlowboardStatus = (visitId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`get_current_flowboard_status_by_visit_id/${visitId}`, {
        headers: { Authorization: token },
      });
    }
  };

  const Prescription = (formData) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/prescriptions`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };
  const updatePrescription = (formData, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/prescriptions/${id}`, {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };
  const fetchPrescriptionById = (patientId, visitId,isCimsData = false) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      let endpoint = isCimsData ? `/get_prescription_by_visit_id/${patientId}/${visitId}?data=1&from_cims=0` :
      `/get_prescription_by_visit_id/${patientId}/${visitId}?data=1`
      return axios.get(
        endpoint,
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const SaveSchedule = (formData) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/save_schedule_slots`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
      // .then(response => response.json())
      // .then(data => console.log(data));
    }
  };

  const leaveAdd = (doctor_leave) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/doctor_leaves`,
        { doctor_leave: doctor_leave },
        { headers: { Authorization: token } }
      );
    }
  };
  const fetchLeave = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let urlData = url ? `?${url}` :''
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_leaves?${urlData}`, {
        headers: { Authorization: token },
      });
    }
  };
  const deleteLeaveId = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/cancel_leave/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const leaveAppointments = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_appointments_in_duration?${url}`, {
        headers: { Authorization: token },
      });
    }
  };

  const StartConsultation = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/doctor_consultations`,
        {},
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const EndConsultation = (time) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/end_consultation`,
        { end_datetime: time },
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const preLogOffConsultation = (data) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/doctor_pre_log_off_histories`,
        { doctor_pre_log_off_history: data },
        {
          headers: { Authorization: token },
        }
      );
    }
  };

  const fetchTodaySchedule = (slotParam) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_slots?${slotParam}`, {
        headers: { Authorization: token },
      });
    }
  };

  const fetchReminder = (url) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/get_doctor_reminders?${url}`, {
        headers: { Authorization: token },
      });
    }
  };
  const fetchReminderById = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/doctor_reminders/${id}`, {
        headers: { Authorization: token },
      });
    }
  };

  const reminderAdd = (doctor_reminder) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/doctor_reminders`,
        { doctor_reminder: doctor_reminder },
        { headers: { Authorization: token } }
      );
    }
  };

  const reminderEdit = (doctor_reminder, id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/doctor_reminders/${id}`,
        { doctor_reminder: doctor_reminder },
        { headers: { Authorization: token } }
      );
    }
  };

  const deleteReminder = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.delete(`/doctor_reminders/${id}`, {
        headers: { Authorization: token },
      });
    }
  };
  const sendToMso = (prescription, pId) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.put(
        `/send_prescription_to_mso/${pId}`,
        { prescription: prescription },
        { headers: { Authorization: token } }
      );
    }
  };

  const feedbackAdd = (formData) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.post(
        `/doctor_to_mso_feedbacks`,
        { doctor_to_mso_feedback: formData },
        { headers: { Authorization: token } }
      );
    }
  };

  const fetchUserProfileData = (id) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return axios.get(`/current_user`, {
        headers: { Authorization: token },
      });
    }
  };

  const userProfileUpdate = (formData) => {
    if (
      localStorage.getItem("jwt") !== "" ||
      localStorage.getItem("jwt") !== undefined
    ) {
      let token = "Bearer " + localStorage.getItem("jwt");
      return fetch(`${baseUrl}/edit_user_profile`, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      });
    }
  };

  // const currentUser = () => {
  //   if (
  //     localStorage.getItem("jwt") !== "" ||
  //     localStorage.getItem("jwt") !== undefined
  //   ) {
  //     let token = "Bearer " + localStorage.getItem("jwt");
  //     fetch(`${baseUrl}/current_user`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //   }
  // };

  return {
    loginUser,
    currentUser,
    logoutUser,
    fetchIndividualPatient,
    fetchCategoryPatient,
    selfAssignPatient,
    fetchMedicalProblem,
    fetchCurrentMedication,
    fetchVital,
    fetchLifestyle,
    fetchFamilyHistory,
    fetchDocuments,
    editMedicalProblem,
    editVital,
    editFamilyHistory,
    editLifeStyle,
    calenderCount,
    calenderAppointment,
    timelineData,
    fetchPatientDetail,
    searchFormulary,
    addFormulary,
    // allInteraction,
    searchPendingTempDrug,
    PathologySuggestion,
    RadiologySuggestion,
    DietSuggestion,
    ExerciseSuggestion,
    DifferentialDiagnosis,
    SaveAllergy,
    fetchAllergy,
    searchDrugFromFormulary,
    flowBoardStatus,
    Prescription,
    leaveAdd,
    fetchLeave,
    deleteLeaveId,
    leaveAppointments,
    StartConsultation,
    EndConsultation,
    SaveSchedule,
    fetchTodaySchedule,
    reminderAdd,
    fetchReminder,
    deleteReminder,
    fetchPrescriptionById,
    sendToMso,
    updatePrescription,
    feedbackAdd,
    searchPatient,
    searchPatientByDiagnosis,
    fetchReminderById,
    reminderEdit,
    fetchFlowboardStatus,
    fetchFamilyHistoryEditData,
    fetchUserProfileData,
    userProfileUpdate,
    preLogOffConsultation,
    getDoctorCurrentSlots,
    fetchMsoInformation
  };
};

const doctorService = service();


export default doctorService;

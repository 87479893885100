import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";

import "./Vitals.css";

const styles = (theme) => ({});

const useStyles = makeStyles(styles);

const VitalData = (props) => {
  const classes = useStyles();
  const { vital } = props;

  return (
    <div>
      {vital.length !== 0 ? (
        <Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="rowVital">
              <div className="columnVital">
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>Weight</span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.weight_kg} Kg
                  </div>
                </div>

                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    Temperature
                  </span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.temperature_f} F
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>Pulse</span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.pulse} per min
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    Oxygen Sat
                  </span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.oxygen_saturation} %
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>BMI</span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.bmi}
                  </div>
                </div>
              </div>
              <div className="columnVital" style={{}}>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>Height</span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.height_cm} cm
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>BP</span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.bps}/{vital[0].attributes?.bpd} mmHg
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    Respiration
                  </span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.respiration} per min
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    Temp.Loc
                  </span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.temp_location?.value}
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    BMI Status
                  </span>
                  <div style={{ fontSize: 12 }}>
                    {vital[0]?.attributes?.bmi_status}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vital: state.vital.vital,
  };
};

export default connect(mapStateToProps, null)(VitalData);
